<template>
<div class="main">
  <div class="main_right">
    <VxeTreeTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions">
    </VxeTreeTable>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      gridOptions: {
        rowId: 'id',
        // 列表功能按钮组
        toolbarConfig: {
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60
          },
          {
            type: "checkbox",
            width: 60
          },
          {
            field: "name",
            title: "菜单名称",
            minWidth: 160,
            treeNode: true,
            align: "left",
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入菜单名称"
              }
            }
          },
          {
            field: "code",
            title: "菜单编码",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                placeholder: "请选择菜单编码"
              }
            }
          },
          {
            field: "appModule",
            title: "所属模块",
            minWidth: 120,
            editRender: {
              name: "$select",
              optionTypeCode: "mobileTab",
              props: {
                placeholder: "请选择所属模块"
              }
            }
          },
          {
            field: "linkAddress",
            title: "链接地址",
            minWidth: 240,
            editRender: {
              name: "$input",
              props: {
                maxlength: 120,
                placeholder: "请输入链接地址"
              }
            }
          },
          {
            field: "loginRequired",
            title: "登录后使用",
            minWidth: 100,
            editRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                placeholder: "请选择登录后使用"
              }
            }
          },

          {
            field: "orderCode",
            title: "排序",
            minWidth: 100,
            editRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入排序"
              }
            }
          },

          {
            field: "icon",
            title: "图标",
            minWidth: 160,
            editRender: {
              name: "$VxePreview",
            }
          },
          {
            field: "enabled",
            title: "状态",
            width: 120,
            cellRender: {
              name: "$switch",
              optionTypeCode: "enabledStatus",
              props: {
                openLabel: "启用",
                closeLabel: "禁用",
                openValue: "1",
                closeValue: "0"
              },
              events: {
                change: ({ row }) => {
                  this.$refs.xGrid.submitEvent(row);
                }
              }
            }
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons"
            }
          }
        ],
        exportConfig: {},
        importConfig: {},
        editConfig: {
          // enabled: false,
        },
        treeConfig: {
          children: 'children',
          reserve: true,
        },
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 140,
        items: [{
            field: "appModule",
            title: "所属模块",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "mobileTab",
              props: {
                placeholder: "请选择所属模块"
              }
            }
          },
          {
            field: 'parentId',
            title: '上级栏目',
            span: 12,
            itemRender: {
              name: "$VxeSelectTree",
              service: "/sys/sysAppMenu",
              defaultParams: {
                clientType: '2'
              },
              options: [],
              props: {
                placeholder: "请选择上级栏目"
              },
              events: {
                click: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                },
              }
            }
          },
          {
            field: "name",
            title: "菜单名称",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入菜单名称"
              }
            }
          },
          {
            field: "code",
            title: "菜单编码",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请选择菜单编码"
              }
            }
          },
          {
            field: "linkAddress",
            title: "链接地址",
            span: 24,
            itemRender: {
              name: "$input",
              props: {
                maxlength: 120,
                placeholder: "请输入链接地址"
              }
            }
          },
          {
            field: "loginRequired",
            title: "登录后使用",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "isIt",
              props: {
                placeholder: "请选择登录后使用"
              }
            }
          },
          {
            field: "orderCode",
            title: "排序",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "integer",
                min: 0,
                max: 999999,
                maxlength: 24,
                placeholder: "请输入排序"
              }
            }
          },
          {
            field: "icon",
            title: "图标",
            span: 12,
            itemRender: {
              name: "$VxeUpload",
              events: {
                click: (params, data) => {
                  params.data["icon"] = data;
                }
              }
            }
          },
          {
            field: "enabled",
            title: "状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "enabledStatus",
              props: {
                placeholder: "请选择状态"
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary"
                  }
                },
                {
                  props: {
                    type: "reset",
                    content: "取消"
                  }
                }
              ]
            }
          }
        ],
        //表单校验规则
        rules: {
          name: [
            { required: true, message: '请输入菜单名称' },
          ],
          code: [
            { required: true, message: '请输入菜单编码' },
          ],
          appModule: [
            { required: true, message: '请选择所属模块' },
          ],
          linkAddress: [
            { required: true, message: '请输入链接地址' },
          ],
          loginRequired: [
            { required: true, message: '请选择登录后使用' },
          ],
          orderCode: [
            { required: true, message: '请输入排序' },
          ],
          enabled: [
            { required: true, message: '请输选择状态' },
          ],
          icon: [
            { required: true, message: '请上传icon' },
          ]
        },
        // 新增功能字段初始化
        data: {
          clientType: '2',
          enabled: "1",
          appModule: "1",
          code: "",
          linkAddress: "/",
          loginRequired: "1",
          name: "",
          orderCode: "",
          icon: "",
          parentId: -1
        },
      },

      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "keyWords",
            title: "名称",
            itemRender: {
              name: "$input",
              props: {
                maxlength: 24,
                placeholder: "请输入名称",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        searchProps: ['name', 'code'],
        data: {
          keyWords: "",
          clientType: '2'
        },
      }
    };
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped></style>
